<mat-drawer-container
  class="main-container"
  [ngClass]="{ visible: titleFontLoaded && bodyFontLoaded }"
  [hasBackdrop]="true"
  *transloco="let t"
>
  <mat-drawer
    #drawer
    [mode]="'over'"
    [opened]="isDrawerOpened"
    class="mat-drawer-container"
  >
    <div class="flex ai-center header mb-40">
      <div class="flex jc-end">
        <mat-icon class="close-icon" (click)="isDrawerOpened = false"
          >close</mat-icon
        >
      </div>
    </div>
    <div class="nav-items d-flex fd-column ai-center gap-40 mt-12 pt-40">
      @if (companyLocation){
      <span
        [ngStyle]="{
          color:
            selectedSection === section.PHOTOS
              ? companyLocation.websiteData.primaryColor
              : '#0d1619'
        }"
        class="nav-item"
        (click)="
          scrollable.scrollToElement('#photos');
          handleScrolling(section.PHOTOS);
          isDrawerOpened = false
        "
        >{{ t("photos") }}</span
      >
      <span
        [ngStyle]="{
          color:
            selectedSection === section.SERVICES
              ? companyLocation.websiteData.primaryColor
              : '#0d1619'
        }"
        class="nav-item"
        (click)="
          scrollable.scrollToElement('#services');
          handleScrolling(section.SERVICES);
          isDrawerOpened = false
        "
        >{{ t("services") }}</span
      >
      <span
        [ngStyle]="{
          color:
            selectedSection === section.TEAM
              ? companyLocation.websiteData.primaryColor
              : '#0d1619'
        }"
        class="nav-item"
        (click)="
          scrollable.scrollToElement('#team');
          handleScrolling(section.TEAM);
          isDrawerOpened = false
        "
        >{{ t("team") }}</span
      >
      <span
        [ngStyle]="{
          color:
            selectedSection === section.ABOUT
              ? companyLocation.websiteData.primaryColor
              : '#0d1619'
        }"
        class="nav-item"
        (click)="
          scrollable.scrollToElement('#about');
          handleScrolling(section.ABOUT);
          isDrawerOpened = false
        "
        >{{ t("about") }}</span
      >
      }
    </div>
  </mat-drawer>
  <mat-drawer-content class="content">
    @if(companyInfo){
    <app-main-navbar
      [companyLogoUrl]="companyInfo.logoUrl"
      [companyLocation]="companyLocation"
      (toggleDrawer)="isDrawerOpened = !isDrawerOpened"
      (scrollToSection)="
        handleScrolling($event.section);
        scrollable.scrollToElement($event.sectionName)
      "
    ></app-main-navbar>
    <ng-scrollbar
      class="scrollable-content"
      visibiliy="hover"
      appearance="compact"
      #scrollable="ngScrollbar"
    >
      <app-photos
        id="photos"
        [companyLocation]="companyLocation"
        [companyName]="companyInfo.name"
        (handleGalleryVisibility)="
          isVisible = $event.isVisible; activeIndex = $event.activeIndex
        "
      ></app-photos>
      @if(locationServices.length>0){
      <app-services
        id="services"
        [companyInfo]="companyInfo"
        [companyLocation]="companyLocation"
        [locationServices]="locationServices"
      ></app-services
      >}
      <div class="fd-row gap-24 layout-container main-content">
        <div class="d-flex fd-column company-info">
          @if(companyLocation.resources.length>0){
          <app-team
            id="team"
            [companyLocation]="companyLocation"
            [companyId]="companyInfo.id"
          ></app-team>
          }
          <app-about
            id="about"
            [companyInfo]="companyInfo"
            [companyLocation]="companyLocation"
          ></app-about>
        </div>
        <div class="flex opening-times">
          <app-opening-times
            class="flex"
            [companyLocation]="companyLocation"
          ></app-opening-times>
        </div>
      </div>
      @if(companyInfo.locations.length>1){
      <app-other-locations
        [companyInfo]="companyInfo"
        [companyLocation]="companyLocation"
      ></app-other-locations>
      } @if((companyLocation.websiteData.enableAppointmentScheduling &&
      locationServices.length > 0) || companyLocation.phoneNumber){
      <div class="book layout-container flex ai-center">
        <span
          class="company-title jc-start"
          [class.service-enabled]="
            companyLocation.websiteData.enableAppointmentScheduling &&
            locationServices.length > 0
          "
          >{{ companyInfo.name }}</span
        >
        <div
          class="flex ai-center gap-12 book-services"
          [class.service-enabled]="
            companyLocation.websiteData.enableAppointmentScheduling &&
            locationServices.length > 0
          "
        >
          @if(companyLocation.websiteData.enableAppointmentScheduling &&
          locationServices.length > 0){
          <span class="service">{{
            locationServices.length > 1
              ? t("services-available", {
                  numOfServices: locationServices.length
                })
              : t("service-available")
          }}</span
          >} @if((companyLocation.websiteData.enableAppointmentScheduling &&
          locationServices.length>0) || companyLocation.phoneNumber){
          <button
            [ngStyle]="{
              'background-color': companyLocation.websiteData.primaryColor
            }"
            (click)="
              companyLocation.websiteData.enableAppointmentScheduling &&
              locationServices.length > 0
                ? navigate()
                : callNow(companyLocation.phoneNumber)
            "
            mat-flat-button
          >
            {{
              companyLocation.websiteData.enableAppointmentScheduling &&
              locationServices.length > 0
                ? t("book-now")
                : t("call-now")
            }}
          </button>
          }
        </div>
      </div>
      }
      <app-footer
        [companyInfo]="companyInfo"
        [companyLocation]="companyLocation"
      ></app-footer>
    </ng-scrollbar>
    }
  </mat-drawer-content>
</mat-drawer-container>

@if(isVisible){
<app-media-preview
  [imageUrls]="transformedImageUrls"
  [isVisible]="isVisible"
  [activeIndex]="activeIndex"
  (handleVisibility)="isVisible = $event"
></app-media-preview>
}
