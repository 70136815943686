import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { FileUrlPipe } from '../pipes/file-url.pipe';

@Component({
  selector: 'app-image',
  standalone: true,
  imports: [TranslocoDirective, FileUrlPipe, CommonModule],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
})
export class ImageComponent {
  @Input() imageSrc!: string | null;
  @Input() alt = '';
  @Input() objectFitContain = false;
  @Input() isAvatar = false;
  isImageLoaded = false;

  /**
   * Handles the end of image loading.
   */
  handleEndLoading(): void {
    this.isImageLoaded = true;
  }
}
