@if(imageSrc){
<img
  [src]="imageSrc | fileUrl | async"
  [ngClass]="{
    visible: isImageLoaded,
    contain: objectFitContain,
    avatar: isAvatar
  }"
  [alt]="alt"
  (load)="handleEndLoading()"
/>
}
