import { EnvTypeEnum } from './model/envtype.enum';

export const environment = {
  production: true,
  type: EnvTypeEnum.DEV,
  apiEndpoint: 'https://api.dev.schedulytic.com/',
  hostname: 'https://business.dev.schedulytic.com/',
  schedwiseHostname: 'https://app.dev.schedulytic.com/',
  googleMapKey: 'AIzaSyAkvWSGvnLuOcIVhWWQuMakM3J2m-3k9Q8',
  firebase: {
    apiKey: 'AIzaSyCGZmgMHVeiZAuKC_p8GD2kKJSv1Tbg4Yw',
    authDomain: 'schedulytic-dev.firebaseapp.com',
    projectId: 'schedulytic-dev',
    storageBucket: 'schedulytic-dev.appspot.com',
    messagingSenderId: '826126483278',
    appId: '1:826126483278:web:32541e7e6a4781f84e681f',
  },
};
