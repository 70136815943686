<div class="gallery">
  <p-galleria
    [@.disabled]="isDisabled"
    [value]="images"
    [visible]="isVisible"
    [activeIndex]="activeIndex"
    [responsiveOptions]="responsiveOptions"
    [numVisible]="5"
    [fullScreen]="true"
    [showItemNavigators]="true"
    (visibleChange)="handleVisibility.emit($event)"
  >
    <ng-template pTemplate="item" let-item>
      <img [src]="item.itemImageSrc" />
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
      <div class="thumbnail">
        <img [src]="item.thumbnailImageSrc" />
      </div>
    </ng-template>
  </p-galleria>
</div>
