<div class="layout-container flex gap-24" *transloco="let t">
  <div class="services-container d-flex fd-column">
    <h2>
      {{ t("services") }}
    </h2>
    <app-select-service
      [currency]="companyInfo.currency"
      [locationServices]="locationServices"
      [companyLocation]="companyLocation"
      [companyId]="companyInfo.id"
    ></app-select-service>
  </div>
  <div class="book-container flex fd-column">
    <div class="book flex fd-column p-32 gap-16">
      <h2
        [class.no-margin]="
          !companyLocation.websiteData.enableAppointmentScheduling &&
          !companyLocation.phoneNumber
        "
      >
        {{
          companyInfo.name +
            (companyLocation.name ? " - " + companyLocation.name : "")
        }}
      </h2>
      @if(companyLocation.websiteData.enableAppointmentScheduling ||
      companyLocation.phoneNumber){
      <button
        [ngStyle]="{
          'background-color': companyLocation.websiteData.primaryColor
        }"
        (click)="
          companyLocation.websiteData.enableAppointmentScheduling
            ? navigate()
            : callNow(companyLocation.phoneNumber)
        "
        mat-flat-button
      >
        {{
          companyLocation.websiteData.enableAppointmentScheduling
            ? t("book-now")
            : t("call-now")
        }}
      </button>
      }
    </div>
    <mat-divider></mat-divider>
    <div class="info flex fd-column p-32 gap-16">
      <div class="time d-flex gap-8">
        <mat-icon>access_time</mat-icon
        ><span>{{ companyLocation.workingHours | workingHours | async }}</span>
      </div>
      <div class="address d-flex gap-8">
        <mat-icon>location_on</mat-icon>
        <span
          >{{ companyLocation.address }}
          <a
            [ngStyle]="{
              color: companyLocation.websiteData.primaryColor
            }"
            [href]="'https://www.google.com/maps?q=' + companyLocation.address"
            target="_blank"
            >{{ t("get-directions") }}</a
          ></span
        >
      </div>
    </div>
  </div>
</div>
