import {
  Directive,
  ElementRef,
  EventEmitter,
  Output,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';

@Directive({
  selector: '[observeVisibility]',
  standalone: true,
})
export class ObserveVisibilityDirective implements AfterViewInit, OnDestroy {
  @Output() visibilityChange = new EventEmitter<boolean>();

  private observer: IntersectionObserver | undefined;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1, // You can adjust the threshold as needed,
    };

    if (typeof window !== 'undefined') {
      // Create IntersectionObserver instance
      this.observer = new IntersectionObserver((entries) => {
        // Check if the intersection ratio is greater than 0 for any entry
        const isVisible = entries.some((entry) => entry.isIntersecting);
        this.visibilityChange.emit(isVisible);
      }, options);

      // Start observing the element
      this.observer.observe(this.elementRef.nativeElement);
    }
  }

  ngOnDestroy() {
    // Disconnect the observer when the directive is destroyed
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
