import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Company } from './model/company';
import { environment } from '../../environments/environment';

@Injectable()
export class CompanyServerService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the company info.
   * @param {string} locationUniqueName - The unique location name
   * @returns {Observable<Company> } - The response from the server.
   */
  getCompanyInfo(locationUniqueName: string): Observable<Company> {
    return this.http.get<Company>(
      environment.apiEndpoint + 'company/public/location/' + locationUniqueName
    );
  }
}
