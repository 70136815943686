import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

import { GalleriaModule } from 'primeng/galleria';
import { ImageInfo } from './models/image-info.model';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-media-preview',
  standalone: true,
  imports: [GalleriaModule, NgClass],
  templateUrl: './media-preview.component.html',
  styleUrl: './media-preview.component.scss',
})
export class MediaPreviewComponent {
  @Input() imageUrls: string[] = [];
  @Input() isVisible = false;
  @Input() activeIndex = 0;
  @Output() handleVisibility = new EventEmitter<boolean>();

  images: ImageInfo[] = [];
  isDisabled = true;

  responsiveOptions: { breakpoint: string; numVisible: number }[] = [
    {
      breakpoint: '1220px',
      numVisible: 4,
    },
    {
      breakpoint: '1024px',
      numVisible: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
    },
  ];

  private componentDestroyed$ = new Subject<void>();

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.initializeImages();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  /**
   * Initializes the images.
   */
  private initializeImages(): void {
    this.translocoService
      .selectTranslate('photo')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((translation) => {
        this.imageUrls.forEach((imageUrl) => {
          this.images.push(<ImageInfo>{
            itemImageSrc: imageUrl,
            thumbnailImageSrc: imageUrl,
            title: '',
            alt: translation,
          });
        });
      });
  }

  /**
   * Navigates through media slider if the ArrowLeft or ArrowRight key is pressed.
   *
   * @param {KeyboardEvent} event - A keyboard event.
   */
  @HostListener('window:keydown', ['$event'])
  private navigate(event: KeyboardEvent): void {
    if (event.key === 'ArrowLeft' && this.activeIndex > 0) {
      this.activeIndex--;
    } else if (
      event.key === 'ArrowRight' &&
      this.activeIndex < this.images.length - 1
    ) {
      this.activeIndex++;
    }
  }
}
