import { Pipe, PipeTransform, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Pipe({
  name: 'fileUrl',
  standalone: true,
})
export class FileUrlPipe implements PipeTransform {
  constructor(private storage: AngularFireStorage) {}

  transform(path: string): Observable<string> {
    const ref = this.storage.refFromURL(path);
    return ref.getDownloadURL();
  }
}
