import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { NgStyle } from '@angular/common';

import { TranslocoDirective } from '@jsverse/transloco';

import { SelectLanguageDialogComponent } from './select-language-dialog/select-language-dialog.component';
import { CompanyLocation } from '../../model/company-location';
import { ImageComponent } from '../../image/image.component';
import { Company } from '../../model/company';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [MatIconModule, TranslocoDirective, ImageComponent, NgStyle],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  @Input() companyInfo!: Company;
  @Input() companyLocation!: CompanyLocation;
  @ViewChild('language') language!: ElementRef;

  constructor(private dialog: MatDialog) {}

  /**
   * Opens the select dialog component.
   */
  openSelectLanguageComponent(): void {
    this.dialog.open(SelectLanguageDialogComponent, {
      data: {
        primaryColor: this.companyLocation.websiteData.primaryColor,
      },
      width: '480px',
      maxWidth: 'calc(100vw - 40px)',
      minHeight: '350px',
      maxHeight: 'calc(100vh - 40px)',
    });
  }
}
