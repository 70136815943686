import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'serviceTime',
  standalone: true,
  pure: false,
})
export class ServiceTimePipe implements PipeTransform {
  private readonly keys = [
    '1 sec',
    'secs',
    '1 min',
    'mins',
    '1 hr',
    'hrs',
    '1 day',
    'days',
    'and',
  ];

  constructor(private translocoService: TranslocoService) {}

  transform(seconds: number): Observable<string> {
    let timeString = '';

    return this.translocoService.selectTranslate(this.keys).pipe(
      map((translations) => {
        const days = Math.floor(seconds / 86400);
        seconds %= 86400;
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;

        const parts: string[] = [];

        if (days > 0) {
          parts.push(
            days === 1
              ? translations[this.getIndex('1 day')]
              : days + ' ' + translations[this.getIndex('days')]
          );
        }
        if (hours > 0) {
          parts.push(
            hours === 1
              ? translations[this.getIndex('1 hr')]
              : hours + ' ' + translations[this.getIndex('hrs')]
          );
        }
        if (minutes > 0) {
          parts.push(
            minutes === 1
              ? translations[this.getIndex('1 min')]
              : minutes + ' ' + translations[this.getIndex('mins')]
          );
        }
        if (seconds > 0) {
          parts.push(
            seconds === 1
              ? translations[this.getIndex('1 sec')]
              : seconds + ' ' + translations[this.getIndex('secs')]
          );
        }

        timeString = parts.join(' ' + translations[this.getIndex('and')] + ' ');
        return timeString;
      })
    );
  }

  /**
   * Gets an index.
   * @param {string} key - A key.
   */
  private getIndex(key: string): number {
    return this.keys.findIndex((val) => val === key);
  }
}
