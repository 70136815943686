<div class="resources-container">
  @for( service of locationServices; track service;let index = $index){
  <div
    class="selection-item d-flex ai-center"
    (click)="
      companyLocation.websiteData.enableAppointmentScheduling
        ? navigate(service.id)
        : null
    "
    (mouseenter)="hoveredServiceIndex = index"
    (mouseleave)="hoveredServiceIndex = -1"
    [ngStyle]="{
      'background-color':
        index === hoveredServiceIndex &&
        companyLocation.websiteData.enableAppointmentScheduling
          ? companyLocation.websiteData.primaryColor + '22'
          : 'white',
      cursor: !companyLocation.websiteData.enableAppointmentScheduling
        ? 'default'
        : 'pointer'
    }"
  >
    <div class="flex fd-column w-100">
      <div class="ellipsis">
        {{ service.name }}
      </div>
      <div class="ellipsis">
        {{ service.durationInSeconds | serviceTime | async }}
      </div>
      @if(service.showPrice){
      <div class="ellipsis">
        {{ currency }}
        {{ service.paymentAmount / 100 | number : "1.2-2" }}
      </div>
      }
    </div>
    @if(companyLocation.websiteData.enableAppointmentScheduling){
    <button
      [ngStyle]="{
        'background-color': companyLocation.websiteData.primaryColor
      }"
      class="chevron-butt d-flex ai-center jc-center"
    >
      <mat-icon
        [ngStyle]="{
          color: 'white'
        }"
        >add</mat-icon
      >
    </button>
    }
  </div>
  }
</div>
