import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

import { Subject, takeUntil } from 'rxjs';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';

import { WorkingHoursPipe } from '../../pipes/working-hours.pipe';
import { CompanyLocation } from '../../model/company-location';
import { environment } from '../../../../environments/environment';
import { GalleryVisibility } from '../../model/gallery-visibility';
import { ImageComponent } from '../../image/image.component';

@Component({
  selector: 'app-photos',
  standalone: true,
  imports: [
    WorkingHoursPipe,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    TranslocoDirective,
    ImageComponent,
  ],
  templateUrl: './photos.component.html',
  styleUrl: './photos.component.scss',
})
export class PhotosComponent implements OnInit, OnDestroy {
  @Input() companyLocation!: CompanyLocation;
  @Input() companyName = '';
  @Output() handleGalleryVisibility = new EventEmitter<GalleryVisibility>();
  @ViewChild('address') addressLink!: ElementRef;
  mailTo!: string;

  private componentDestroyed$ = new Subject<void>();

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.translocoService
      .selectTranslate('email-body-content')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((translation) => {
        const url = environment.hostname + this.companyLocation.uniqueName;
        const subject = encodeURIComponent(this.companyName);
        const body = encodeURIComponent(`${translation}\n${url}`);

        this.mailTo = `mailto:?subject=${subject}&body=${body}`;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  /**
   * Opens the media preview.
   *
   * @param {number} index - The active index..
   */
  openMediaPreview(index: number): void {
    this.handleGalleryVisibility.emit(<GalleryVisibility>{
      isVisible: true,
      activeIndex: index,
    });
  }
}
