<div
  class="layout-container navbar-container d-flex ai-center"
  *transloco="let t"
  #navContainer
>
  @if(companyLogoUrl){
  <div #logo class="company-logo">
    <app-image
      [imageSrc]="companyLogoUrl"
      [alt]="t('logo')"
      [objectFitContain]="true"
    ></app-image>
  </div>
  }
  <div class="nav-items flex gap-16 jc-end">
    <span
      [ngStyle]="{
        color: companyLocation.websiteData.navigationTextColor,
        'border-bottom':
          selectedSection === section.PHOTOS
            ? '4px solid' + companyLocation.websiteData.navigationTextColor
            : '4px solid transparent'
      }"
      class="nav-item"
      (click)="handleSelecting(section.PHOTOS)"
      >{{ t("photos") }}</span
    >
    <span
      [ngStyle]="{
        color: companyLocation.websiteData.navigationTextColor,
        'border-bottom':
          selectedSection === section.SERVICES
            ? '4px solid' + companyLocation.websiteData.navigationTextColor
            : '4px solid transparent'
      }"
      class="nav-item"
      (click)="handleSelecting(section.SERVICES)"
      >{{ t("services") }}</span
    >
    <span
      [ngStyle]="{
        color: companyLocation.websiteData.navigationTextColor,
        'border-bottom':
          selectedSection === section.TEAM
            ? '4px solid' + companyLocation.websiteData.navigationTextColor
            : '4px solid transparent'
      }"
      class="nav-item"
      (click)="handleSelecting(section.TEAM)"
      >{{ t("team") }}</span
    >
    <span
      [ngStyle]="{
        color: companyLocation.websiteData.navigationTextColor,
        'border-bottom':
          selectedSection === section.ABOUT
            ? '4px solid' + companyLocation.websiteData.navigationTextColor
            : '4px solid transparent'
      }"
      class="nav-item"
      (click)="handleSelecting(section.ABOUT)"
      >{{ t("about") }}</span
    >
    <span class="d-flex ai-center icon" #menuIcon (click)="toggleDrawer.emit()"
      ><mat-icon
        [ngStyle]="{
          color: companyLocation.websiteData.navigationTextColor
        }"
        >menu</mat-icon
      ></span
    >
  </div>
</div>
