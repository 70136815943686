import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';

import { CompanyLocation } from '../../model/company-location';
import { environment } from '../../../../environments/environment';
import { FileUrlPipe } from '../../pipes/file-url.pipe';
import { ImageComponent } from '../../image/image.component';

@Component({
  selector: 'app-team',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, FileUrlPipe, ImageComponent],
  templateUrl: './team.component.html',
  styleUrl: './team.component.scss',
})
export class TeamComponent {
  @Input() companyLocation!: CompanyLocation;
  @Input() companyId!: string;

  constructor(private translocoService: TranslocoService) {}

  /**
   * Navigates to the booking site.
   * @param {string} resourceId - A resource id.
   */
  navigate(resourceId: string): void {
    if (typeof window !== 'undefined') {
      window.location.href = window.location.href =
        environment.schedwiseHostname +
        '/public/' +
        this.companyId +
        '?preselectNavigation=true&locationId=' +
        this.companyLocation.id +
        '&resourceId=' +
        resourceId +
        '&lang=' +
        this.translocoService.getActiveLang();
    }
  }
}
