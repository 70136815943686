import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgFor, NgClass, AsyncPipe, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { TranslocoDirective } from '@jsverse/transloco';

import { ObserveVisibilityDirective } from './observe-visibility.directive';
import { Company } from '../../model/company';
import { environment } from '../../../../environments/environment';
import { CompanyLocation } from '../../model/company-location';
import { FileUrlPipe } from '../../pipes/file-url.pipe';
import { ImageComponent } from '../../image/image.component';

@Component({
  selector: 'app-other-locations',
  standalone: true,
  imports: [
    NgFor,
    NgClass,
    NgStyle,
    AsyncPipe,
    TranslocoDirective,
    MatIconModule,
    ObserveVisibilityDirective,
    FileUrlPipe,
    ImageComponent,
  ],
  templateUrl: './other-locations.component.html',
  styleUrl: './other-locations.component.scss',
})
export class OtherLocationsComponent implements OnInit, AfterViewInit {
  @Input() companyInfo!: Company;
  @Input() companyLocation!: CompanyLocation;
  @ViewChild('locationsContainer')
  locationsContainer!: ElementRef;

  firstLocationVisible = false;
  lastLocationVisible = false;
  hideToogleButtons = true;
  environment = '';

  @ViewChildren('explore') navItems!: QueryList<ElementRef>;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.environment = environment.hostname;
  }

  ngAfterViewInit(): void {
    this.hideToogleButtons =
      this.locationsContainer.nativeElement.scrollWidth <=
      this.locationsContainer.nativeElement.clientWidth;
    this.cdr.detectChanges();
  }

  /**
   * Navigates the locations slider to the left.
   */
  navigateLeft(): void {
    const clientWidth = this.locationsContainer.nativeElement.clientWidth;
    const diff =
      this.locationsContainer.nativeElement.scrollWidth -
      (clientWidth + this.locationsContainer.nativeElement.scrollLeft);

    diff > clientWidth
      ? (this.locationsContainer.nativeElement.scrollLeft -= diff)
      : (this.locationsContainer.nativeElement.scrollLeft -= clientWidth);
  }

  /**
   * Navigates the locations slider to the right.
   */
  navigateRight(): void {
    const clientWidth = this.locationsContainer.nativeElement.clientWidth;
    const diff =
      this.locationsContainer.nativeElement.scrollWidth -
      (clientWidth + this.locationsContainer.nativeElement.scrollLeft);

    diff > clientWidth
      ? (this.locationsContainer.nativeElement.scrollLeft += clientWidth)
      : (this.locationsContainer.nativeElement.scrollLeft += diff);
  }
}
