<div class="team-container d-flex fd-column" *transloco="let t">
  <h2>{{ t("team") }}</h2>
  <div class="flex gap-20 f-wrap resources">
    @for (resource of companyLocation.resources; track resource) {
    <div
      class="d-flex resource ai-center fd-column"
      (click)="
        companyLocation.websiteData.enableAppointmentScheduling
          ? navigate(resource.id)
          : null
      "
    >
      <div class="avatar flex ai-center jc-center">
        @if(resource.avatarUrl ){
        <app-image
          [imageSrc]="resource.avatarUrl"
          [isAvatar]="true"
          [alt]="t('photo')"
        ></app-image>
        }@else{
        <div class="initial">{{ resource.firstName | slice : 0 : 1 }}</div>
        }
      </div>

      <div class="flex fd-column">
        <div class="first-name text-ellipsis">
          {{ resource.firstName }}
        </div>
        @if( companyLocation.websiteData.showFullPersonNames){
        <div class="first-name text-ellipsis">
          {{ resource.lastName }}
        </div>
        }
      </div>
    </div>
    }
  </div>
</div>
