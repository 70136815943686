<div class="dialog-container" *transloco="let t">
  <div mat-dialog-title>{{ t("change-language") }}</div>
  <mat-dialog-content>
    <div class="flex fd-column gap-24">
      <span class="change-language-info">{{ t("change-language-info") }}</span>

      <div class="flex fd-column gap-4">
        <label for="cars">{{ t("language") }}</label>
        <div class="select-container flex ai-center">
          <div class="flex ptb-16 pr-8 pl-16 selected-language">
            {{ selectedLanguage.label }}
          </div>
          <div class="icon-container pr-16">
            <mat-icon>expand_more</mat-icon>
          </div>
          <select name="language" (change)="handleSelecting($event)">
            @for (language of languageList; track language) {
            <option
              value="{{ language.code }}"
              [selected]="selectedLanguage.code === language.code"
            >
              {{ language.label }}
            </option>
            }
          </select>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      [ngStyle]="{
        'background-color': data.primaryColor
      }"
      mat-button
      cdkFocusInitial
      (click)="handleClosing()"
    >
      {{ t("save") }}
    </button>
  </mat-dialog-actions>
</div>
