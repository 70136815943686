import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  AsyncPipe,
  DecimalPipe,
  NgClass,
  NgFor,
  NgStyle,
} from '@angular/common';

import { TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil } from 'rxjs';

import { Service } from '../../../model/service';
import { CompanyLocation } from '../../../model/company-location';
import { PaymentCurrency } from '../../../model/payment-currency';
import { environment } from '../../../../../environments/environment';
import { ServiceTimePipe } from '../pipe/service-time.pipe';

@Component({
  selector: 'app-select-service',
  standalone: true,
  imports: [
    MatIconModule,
    NgFor,
    NgClass,
    NgStyle,
    DecimalPipe,
    ServiceTimePipe,
    AsyncPipe,
  ],
  templateUrl: './select-service.component.html',
  styleUrl: './select-service.component.scss',
})
export class SelectServiceComponent implements OnInit, OnDestroy {
  services: Service[] = [];

  @Input() companyLocation!: CompanyLocation;
  @Input() currency!: PaymentCurrency;
  @Input() locationServices: Service[] = [];
  @Input() companyId = '';

  attributeBase = '';
  hoveredServiceIndex = -1;

  private componentDestroyed$ = new Subject<void>();

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.generateBaseUrl();
    this.translocoService.langChanges$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.generateBaseUrl());
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  /**
   * Generates a base url for navigation.
   */
  generateBaseUrl(): void {
    this.attributeBase =
      environment.schedwiseHostname +
      '/public/' +
      this.companyId +
      '?preselectNavigation=true' +
      '&locationId=' +
      this.companyLocation.id +
      '&lang=' +
      this.translocoService.getActiveLang();
  }

  /**
   * Navigates to the booking site.
   * @param {string} serviceId - A service Id.
   */
  navigate(serviceId: string): void {
    if (typeof window !== 'undefined') {
      window.open(this.attributeBase + '&serviceId=' + serviceId, '_blank');
    }
  }
}
