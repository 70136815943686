<div class="footer-container flex fd-column" *transloco="let t">
  @if(companyInfo.socials && ( companyInfo.socials.facebook ||
  companyInfo.socials.instagram || companyInfo.socials.linkedIn ||
  companyInfo.socials.twitter)) {
  <div class="link-container">
    <div class="link layout-container">
      @if(companyInfo.logoUrl){
      <div
        class="company-logo"
        [ngStyle]="{
          'max-width': companyLocation.websiteData.logoWidth + 'px',
          'max-height': companyLocation.websiteData.navigationHeight + 'px'
        }"
      >
        <app-image
          [imageSrc]="companyInfo.logoUrl"
          [alt]="t('logo')"
          [objectFitContain]="true"
        ></app-image>
      </div>
      } @if(companyInfo.socials && (companyInfo.socials.facebook ||
      companyInfo.socials.instagram || companyInfo.socials.linkedIn ||
      companyInfo.socials.facebook || companyInfo.socials.twitter)) {
      <div class="d-flex fd-column">
        <p>{{ t("socials") }}</p>
        <div class="socials d-flex gap-16">
          @if(companyInfo.socials.facebook){
          <a
            class="d-flex gap-8"
            [href]="companyInfo.socials.facebook"
            target="_blank"
          >
            <mat-icon class="elumity-icon-medium" svgIcon="facebook"></mat-icon
            ><span>{{ t("facebook") }}</span></a
          >} @if(companyInfo.socials.instagram){
          <a
            class="d-flex gap-8"
            [href]="companyInfo.socials.instagram"
            target="_blank"
          >
            <mat-icon class="elumity-icon-medium" svgIcon="instagram"></mat-icon
            ><span>{{ t("instagram") }}</span></a
          >} @if(companyInfo.socials.linkedIn){
          <a
            class="d-flex gap-8"
            [href]="companyInfo.socials.linkedIn"
            target="_blank"
          >
            <mat-icon class="elumity-icon-medium" svgIcon="linkedIn"></mat-icon
            ><span>{{ t("linkedln") }}</span></a
          >} @if(companyInfo.socials.twitter){
          <a
            class="d-flex gap-8"
            [href]="companyInfo.socials.twitter"
            target="_blank"
          >
            <mat-icon class="elumity-icon-medium" svgIcon="twitter"></mat-icon
            ><span>{{ t("twitter") }}</span></a
          >}
        </div>
      </div>
      }
    </div>
  </div>
  }
  <div class="bottom layout-container flex ai-center">
    <div
      class="language d-flex gap-8 ai-center jc-start"
      (click)="openSelectLanguageComponent()"
    >
      <mat-icon
        [ngStyle]="{
          color: companyLocation.websiteData.primaryColor
        }"
        class="elumity-icon-medium"
        >language</mat-icon
      >
      <span
        [ngStyle]="{
          color: companyLocation.websiteData.primaryColor
        }"
        >{{ t("active-language") }}</span
      >
    </div>

    <div class="flex jc-end gap-4">
      Powered by
      <a
        [ngStyle]="{
          color: companyLocation.websiteData.primaryColor
        }"
        href="{{ t('schedwise-link') }}"
        target="_blank"
      >
        Schedwise
      </a>
    </div>
  </div>
</div>
