import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare let DOMPurify: any;

@Pipe({
  name: 'nl2br',
  standalone: true,
})
export class Nl2brPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(dirty: string) {
    let purified = DOMPurify.sanitize(dirty);

    purified = purified.replace(/(?:\r\n|\r|\n)/g, '<br />');
    purified = purified.replace('&lt;br&gt;', '<br />');

    return this.sanitizer.bypassSecurityTrustHtml(purified);
  }
}
