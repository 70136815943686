import { NgClass, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TranslocoDirective } from '@jsverse/transloco';

import { Sections } from '../../model/sections.enum';
import { CompanyLocation } from '../../model/company-location';
import { ImageComponent } from '../../image/image.component';

@Component({
  selector: 'app-main-navbar',
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    MatIconModule,
    TranslocoDirective,
    ImageComponent,
  ],
  templateUrl: './main-navbar.component.html',
  styleUrl: './main-navbar.component.scss',
})
export class MainNavbarComponent implements AfterViewInit {
  @Input() companyLogoUrl = '';
  @Input() companyLocation!: CompanyLocation;
  @Output() toggleDrawer = new EventEmitter<void>();
  @Output() scrollToSection = new EventEmitter<{
    section: Sections;
    sectionName: string;
  }>();

  @ViewChild('navContainer') navContainer!: ElementRef;
  @ViewChild('logo') logo!: ElementRef;

  section = Sections;
  selectedSection = Sections.PHOTOS;

  private readonly LOGO_OFFSET = 4;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.setNavbarStyles();
  }

  /**
   * Handles selecting a section
   * @param {Sections} section - A section.
   */
  handleSelecting(section: Sections): void {
    this.scrollToSection.emit({
      section: section,
      sectionName: section.toString(),
    });
    this.selectedSection = section;
  }

  /**
   * Sets the navbar styles (color and logo).
   */
  private setNavbarStyles(): void {
    this.renderer.setStyle(
      this.navContainer.nativeElement.parentNode,
      'backgroundColor',
      this.companyLocation.websiteData.navigationBackgroundColor
    );

    this.renderer.setStyle(
      this.navContainer.nativeElement,
      'height',
      this.companyLocation.websiteData.navigationHeight + 'px'
    );

    if (this.companyLogoUrl) {
      this.renderer.setStyle(
        this.logo.nativeElement,
        'max-height',
        this.companyLocation.websiteData.navigationHeight -
          this.LOGO_OFFSET +
          'px'
      );

      this.renderer.setStyle(
        this.logo.nativeElement,
        'max-width',
        this.companyLocation.websiteData.logoWidth + 'px'
      );
    }
  }
}
