<div class="other-locations-container layout-container" *transloco="let t">
  <h3>{{ t("explore-other-locations") }}</h3>
  <div class="carousel flex">
    @if(!firstLocationVisible && !hideToogleButtons){
    <div
      class="slider-toggle left ai-center jc-center"
      (click)="navigateLeft()"
    >
      <mat-icon> chevron_left </mat-icon>
    </div>
    }
    <div class="carousel-grid" #locationsContainer>
      @for(location of companyInfo.locations; track location;let first = $first,
      last = $last){ @if(location.id!==companyLocation.id){ @if(first){
      <div
        class="location-container"
        observeVisibility
        (visibilityChange)="firstLocationVisible = $event"
      >
        <div class="location d-flex fd-column">
          <div class="image-container">
            @if(location.websiteData.imageUrls[0]){
            <app-image
              [imageSrc]="location.websiteData.imageUrls[0]"
              [alt]="t('photo')"
            ></app-image>
            }@else{
            <img
              src="../../assets/images/placeholder-image.png"
              alt="{{ t('photo') }}"
            />}
          </div>
          <div class="address d-flex p-12 fd-column gap-12">
            <span class="text-ellipsis"
              >{{
                (location.name ? location.name + " - " : "") + location.address
              }}
            </span>
            <a
              [ngStyle]="{
                'background-color': companyLocation.websiteData.primaryColor
              }"
              class="explore p-8"
              target="_blank"
              href="{{ environment + location.uniqueName }}"
            >
              {{ t("explore") }}
            </a>
          </div>
        </div>
      </div>
      } @else if(last){
      <div
        class="location-container"
        observeVisibility
        (visibilityChange)="lastLocationVisible = $event"
      >
        <div class="location d-flex fd-column">
          <div class="image-container">
            @if(location.websiteData.imageUrls[0]){
            <app-image
              [imageSrc]="location.websiteData.imageUrls[0]"
              [alt]="t('photo')"
            ></app-image>
            }@else{
            <img
              src="../../assets/images/placeholder-image.png"
              alt="{{ t('photo') }}"
            />}
          </div>
          <div class="address d-flex p-12 fd-column gap-12">
            <span class="text-ellipsis">{{
              (location.name ? location.name + " - " : "") + location.address
            }}</span>
            <a
              [ngStyle]="{
                'background-color': companyLocation.websiteData.primaryColor
              }"
              class="explore p-8"
              target="_blank"
              href="{{ environment + location.uniqueName }}"
            >
              {{ t("explore") }}
            </a>
          </div>
        </div>
      </div>
      } @else{
      <div class="location-container">
        <div class="location d-flex fd-column">
          <div class="image-container">
            @if(location.websiteData.imageUrls[0]){
            <app-image
              [imageSrc]="location.websiteData.imageUrls[0]"
              [alt]="t('photo')"
            ></app-image>
            }@else{
            <img
              src="../../assets/images/placeholder-image.png"
              alt="{{ t('photo') }}"
            />}
          </div>
          <div class="address d-flex p-12 fd-column gap-12">
            <span class="text-ellipsis"
              >{{
                (location.name ? location.name + " - " : "") + location.address
              }}
            </span>
            <a
              [ngStyle]="{
                'background-color': companyLocation.websiteData.primaryColor
              }"
              class="explore p-8"
              target="_blank"
              href="{{ environment + location.uniqueName }}"
            >
              {{ t("explore") }}
            </a>
          </div>
        </div>
      </div>
      } } }
    </div>

    @if(!lastLocationVisible && !hideToogleButtons){
    <div
      class="slider-toggle right ai-center jc-center"
      (click)="navigateRight()"
    >
      <mat-icon> chevron_right </mat-icon>
    </div>
    }
  </div>
</div>
